<template>
  <div v-if="canViewVerticalNavMenuLink(item)">
    <v-list-item
      v-if="!item.children"
      v-bind="linkProps"
      :class="{'bg-gradient-primary white--text': isActive}"
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
    >
      <v-list-item-icon>
        <v-icon
          :class="{'alternate-icon-small': !item.icon}"
          :color="isActive ? 'white': null"
        >
          {{ item.icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title :class="{'white--text': isActive}">
        {{ t(item.title) }}
      </v-list-item-title>
      <v-list-item-action
        v-if="item.badge"
        class="flex-shrink-0"
      >
        <v-badge
          :color="item.badgeColor"
          inline
          :content="item.badge"
        >
        </v-badge>
      </v-list-item-action>
    </v-list-item>
    <v-list-group
      v-else
      v-bind="linkProps"
      :value="isExpanded"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon slot="prependIcon">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </template>
      <div
        v-if="item.children && item.children.length > 0"
      >
        <div
          v-for="child in item.children"
          :key="child.title"
        >
          <v-list-item
            :to="child.to"
            :class="{'bg-gradient-primary white--text': isActive}"
            class="vertical-nav-menu-link"
            active-class="bg-gradient-primary"
          >
            <v-list-item-icon>
              <v-icon
                :class="{'alternate-icon-small': !child.icon}"
                :color="isActive ? 'white': null"
              >
                {{ child.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item>
        </div>
      </div>
    </v-list-group>
  </div>

  <!--

    -->
</template>

<script>
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'
import { useRouter } from '@/@core/utils'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item)
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const { route } = useRouter()

    let isExpanded = false
    if (props.item.children) {
      props.item.children.forEach(child => {
        if (child.to === route.value.fullPath) {
          isExpanded = true
        }
      })
    }

    return {
      isActive,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,
      isExpanded,
      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
