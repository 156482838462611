<template>
  <div>
    <component
      :is="linkComponent"
      :item="{ title: 'Administrators', icon: mdiAccountSupervisorOutline, to: 'administrators' }"
      class="mx-1"
    />
    <component
      :is="linkComponent"
      :item="{ title: 'Devices', icon: mdiViewListOutline , to: '/devices', children: [{ title: 'Pricing', icon: mdiViewListOutline , to: '/pricing' }, { title: 'Availability', icon: mdiViewListOutline , to: '/availability' }] }"
      class="mx-1"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mdiAccountSupervisorOutline, mdiViewListOutline, mdiChartBoxOutline } from '@mdi/js'
import HorizontalNavMenuHeaderLink from '@core/layouts/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'

export default {
  name: 'NavLinks',

  components: {
    HorizontalNavMenuHeaderLink,
    VerticalNavMenuLink,
  },

  props: {
    linkComponent: {
      required: true,
      type: String,
    },
  },

  setup() {
    return {
      mdiAccountSupervisorOutline,
      mdiViewListOutline,
      mdiChartBoxOutline,
    }
  },
}
</script>

<style scoped>
