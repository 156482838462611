/* eslint-disable */
import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '@axios'
import { ref, getCurrentInstance } from '@vue/composition-api'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      // if (userRole === 'admin') return { name: 'dashboard-crm' }
      // if (userRole === 'client') return { name: 'page-access-control' }
      if (userData !== null && userRole !== null) return { name: 'administrators' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',

      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/administrators',
    name: 'administrators',
    component: () => import('@/views/Administrators.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/Devices.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/devices/:id',
    name: 'sensors',
    component: () => import('@/views/Sensors.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/devices/:id/sensor/:sensor_id',
    name: 'sensor_data',
    component: () => import('@/views/SensorsData.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/Analytics.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/availability',
    name: 'availability',
    component: () => import('@/views/Availability.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
},
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('@/views/Pricing.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
        },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  // Redirect to login if not logged in
  if (isLoggedIn === null && to.path !== '/login') return next('/login')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  if (to.path !== '/login') {
    axios
      .get('/role')
      .then(response => {
        // Set user's ability in localStorage for Access Control
        localStorage.setItem('userAbility', response.data.role)
      })
      .catch(e => {
        router.app.$toastr.e(e?.message)
      })
  }

  return next()
})

export default router
